import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { ProductsComponent } from './component/products/products.component';
import { ServicesComponent } from './component/services/services.component';
import { JobPostComponent } from './component/job-post/job-post.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CareersComponent } from './component/careers/careers.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { EmployeesComponent } from './component/about-us/employees/employees.component';
import { JobResponsibilitiesComponent } from './component/job-responsibilities/job-responsibilities.component';
import { ContactUsService } from './core/services/contact-us.service';
import { ContentfulService } from './core/services/contentful.service';
import { JobsComponent } from './component/careers/jobs/jobs.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { JobPostService } from './core/services/job-post.service';
import { environment } from '../environments/environment';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { RecaptchaModule, RecaptchaFormsModule , RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProductsComponent,
    HomeComponent,
    ContactUsComponent,
    ServicesComponent,
    JobPostComponent,
    CareersComponent,
    AboutUsComponent,
    JobResponsibilitiesComponent,
    EmployeesComponent,
    JobsComponent,

  ],
  imports: [
    TextareaAutosizeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [ContactUsService, ContentfulService, JobPostService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey,
      } as RecaptchaSettings,
    }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
