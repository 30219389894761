<div class="common">
    <div class="inline-flex">
        <div class="h1-header">
            <h1>A full-service agile software development shop</h1>
        </div>
        <div class="img-style" *ngIf="innerWidth > 1024">
            <img src="{{fullImgPath}}careers-desktop.png" alt="Careers">
        </div>
        <div class="img-style" *ngIf="innerWidth > 739 && innerWidth < 1025">
            <img src="{{fullImgPath}}careers-tablet.png" alt="Careers">
        </div>
        <div class="img-style" *ngIf="innerWidth < 740">
            <img src="{{fullImgPath}}careers-mobile.png" alt="Careers">
        </div>
    </div>
    <div class="content-mobile-50">
        <div class="content">
            <div class="title">
                <h5>Professional Services</h5>
            </div>
            <div class="professional-description">
                <p>Whether you want to reduce costs, boost worker productivity or increase customer engagement, it all
                    starts with PavoCom. By identifying your business’s mobility goals, PavoCom, provides a clear,
                    tactical
                    plan to reach your goals.</p>
                <p>PavoCom Consulting is about more than technological expertise. We guide you through a highly
                    focused,
                    multi-step process that can help you identify, design and integrate solutions exclusively for your
                    organization. By creating a strategic assessment, opportunity analysis and mobility roadmap, you
                    can
                    achieve a variety of business goals.</p>
                <div *ngIf="innerWidth > 739">
                    <p>Benefit from the accelerated time to a market that can be achieved with our integrated,
                        streamlined
                        offers and practices. Avoid the risks of incompatible technologies with multiple vendors,
                        and
                        work
                        with
                        a provider who can quickly gather your needs and weave the appropriate components into a
                        streamlined
                        solution.</p>
                    <p>PavoCom consultants can help you plan and deploy customized solutions that improve your most
                        important
                        business processes. However, technology is anything but static—should you need additional
                        resources
                        or
                        personnel, PavoCom can help you manage and fine-tune your mobility solutions as your
                        business
                        evolves.</p>
                </div>
                <div *ngIf="innerWidth < 740">
                    <div class="read" *ngIf="!!showOrHideRead">
                        <span id="read-more" (click)="readMore()">Read more</span>
                    </div>
                    <div *ngIf="showReadMore">
                        <p>Benefit from the accelerated time to a market that can be achieved with our integrated,
                            streamlined
                            offers and practices. Avoid the risks of incompatible technologies with multiple vendors,
                            and
                            work
                            with
                            a provider who can quickly gather your needs and weave the appropriate components into a
                            streamlined
                            solution.</p>
                        <p>PavoCom consultants can help you plan and deploy customized solutions that improve your most
                            important
                            business processes. However, technology is anything but static—should you need additional
                            resources
                            or
                            personnel, PavoCom can help you manage and fine-tune your mobility solutions as your
                            business
                            evolves.</p>
                    </div>
                    <div class="read" *ngIf="!showOrHideRead">
                        <span id="read-less" (click)="readLess()">Read less</span>
                    </div>
                </div>
            </div>
            <div class="margin-72">
                <div class="title">
                    <h5>Network Services</h5>
                </div>
                <div class="network-description">
                    <p>As an AT&T Partner Exchange Platinum Solution Provider, we offer our clients access to a wide
                        variety of
                        services, including VPN, MIS, MPLS Private Network Transport and IP Flexible Reach.</p>
                </div>
            </div>
        </div>
    </div>
</div>