import { Component, OnInit } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { ContentfulService } from 'src/app/core/services/contentful.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  public fullImgPath = environment.fullImgPath;
  public employees: Observable<any>;

  constructor(private contentful: ContentfulService) { }

  ngOnInit() {
    this.employees = this.contentful.getContent('employees');
  }

}
