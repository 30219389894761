import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_URLS } from '../../app-routing.module';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public fullImgPath = environment.fullImgPath;
  public linkedIn = environment.LinkedInLink;
  public fb = environment.FbLink;
  public isMenuShowing = false;
  public headerContent = false;
  public innerWidth: any;

  constructor( private renderer: Renderer2, private router: Router ) {
   }
  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }
  public display() {
    this.isMenuShowing = !this.isMenuShowing; // when click on exit icon display three dashes.
    this.headerContent = !this.headerContent;
  }
  public hideMenu() {
    this.isMenuShowing = false;
    this.headerContent = false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
