<div class="common" *ngIf="jobs | async as item">
    <div>
        <h1 class="h1-header">{{item.jobTitle}}</h1>
    </div>
    <div class="p-style">
        <div *ngFor="let val of item.jobDescription.content">
            <p>{{val.content[0].value}}</p>
        </div>
        <p>The websites that will be directly impacted by this position: <span class="red-link"><a
                    href="https://www.freeupmobile.com" rel="noopener" target="_blank">www.freeupmobile.com</a></span>,
            <span class="red-link"><a href="https://www.fredssmartmobile.com" rel="noopener"
                    target="_blank">www.fredssmartmobile.com</a></span>, and another website in progress.
        </p>
    </div>
    <div>
        <h2 class="h2-header res-top">{{item.responsibilities}}</h2>
        <div class="dashed desc-style" *ngFor="let val of item.responsibilitiesItems.content">

            {{val.content[0].value}}

        </div>
    </div>
    <div>
        <h2 class="h2-header res-top">{{item.skillsQualifications}}</h2>
        <div class="dashed desc-style" *ngFor="let val of item.qualificationsItems.content">
            {{val.content[0].value}}
        </div>
    </div>
    <div>
        <h2 class="h2-header res-top">{{item.benefitsAndPerksSubtitle}}</h2>
        <div class="dashed desc-style" *ngFor="let val of item.benefitsItems.content">
            {{val.content[0].value}}
        </div>
    </div>
    <div class="top"><a routerLink="application-form" class="submit-link">Apply</a> </div>
</div>