import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_URLS } from 'src/app/app-routing.module';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public fullImgPath = environment.fullImgPath;
  public linkedIn = environment.LinkedInLink;
  public fb = environment.FbLink;
  constructor(private router: Router) {
  }

  ngOnInit() {
  }
  public clickHome() {
    this.router.navigate([ROUTE_URLS.HOME]);
  }
}
