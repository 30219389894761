export class JobPostModel {
    location: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    languages: string;
    workEligibility: string;
    hispanic: string;
    visaSponsorship: string;
    gender: string;
    socialProfile: SocialProfile;
    leadSource: string;
    position: Position;
}
export class SocialProfile {
    platform: string;
    url: string;
}
export class Position {
    id: string;
    city: string;
    title: string;
}


