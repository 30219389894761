export const environment = {
    production: false,
    siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    fullImgPath: '../../../assets/img/',
    FbLink: 'https://www.facebook.com/pavocommunications',
    LinkedInLink: 'https://www.linkedin.com/company/pavocom',
    LinkedInJobsLink: 'https://www.linkedin.com/company/pavocom/jobs/',
    contentful: {
        spaceId: '4f5mkbh9xu4v',
        token: 'UcmxMXnrmQHrXwhtt_DSJ7LFbPs1xAnWbz7ag3UtQS0',
      },
      firebase: {
        apiKey: 'AIzaSyDd3IdHhaaykaTL0B2PErk8AvXF9aol7L4',
        authDomain: 'pavocom-site-dev.firebaseapp.com',
        databaseURL: 'https://pavocom-site-dev.firebaseio.com',
        projectId: 'pavocom-site-dev',
        storageBucket: 'pavocom-site-dev.appspot.com',
        messagingSenderId: '340260395374',
        appId: '1:340260395374:web:7cd7c6d82c334b15f5497f'
      }
};
