import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ContentfulService } from 'src/app/core/services/contentful.service';
import {Job } from '../../core/models/job.model';



@Component({
  selector: 'app-job-responsibilities',
  templateUrl: './job-responsibilities.component.html',
  styleUrls: ['./job-responsibilities.component.scss']
})
export class JobResponsibilitiesComponent implements OnInit {
  @Input() contentTitle: string ;
  @Input() jobId: string;
  @Input() jobPosition: string;
  public idJob: string;
  public position: string;
  public jobs: Observable<any>;

  public title: string;

  constructor(private contentful: ContentfulService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.title = this.route.snapshot.paramMap.get('contentTitle').charAt(0).toUpperCase()
    + this.route.snapshot.paramMap.get('contentTitle').slice(1).toLowerCase().split('-').join(' ');
    this.idJob = this.route.snapshot.paramMap.get('jobId').toUpperCase();
    this.position = this.route.snapshot.paramMap.get('jobPosition').charAt(0).toUpperCase() +
    this.route.snapshot.paramMap.get('jobPosition').slice(1).toLowerCase() ;
    this.jobs = this.contentful.getJobDesc('jobPost', this.idJob);
  }
}



