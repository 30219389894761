<!-- <h3>Normal mode</h3>
	<re-captcha></re-captcha>

	<h3>Invisible CAPTCHA</h3>
	<re-captcha size="invisible" #invisible (resolved)="response = $event"></re-captcha>
	<button (click)="invisible.execute()">Execute</button>
	<button (click)="invisible.reset()">Reset</button>
	<h4>Invisible captcha response</h4>
	<pre>{{ response || '[empty]' }}</pre> -->

<div class="common">
    <h1 class="h1-header">We’d love to hear from you!</h1>
    <h3 class="h3-header">Don’t hesitate to reach out using the form on your left or by one of the
        following methods below.</h3>

    <form class="material-form" (ngSubmit)="invisible.execute()" [formGroup]="contactUsForm">
        <section>
            <mat-form-field class="field-style" disableRipple>
                <input id="name" matInput placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="contactUsForm.controls.name.touched && !name && !(contactUsForm.controls.name.hasError('pattern'))">
                    Name is required</mat-error>
                <mat-error *ngIf="contactUsForm.controls.name.hasError('pattern')">Name is invalid</mat-error>
            </mat-form-field>

            <mat-form-field class="field-style">
                <input id="phone-number" matInput placeholder="Phone" maxlength="10" minlength="10" formControlName="phone"
                    required>
                <mat-error *ngIf="contactUsForm.controls.phone.touched && !phone && !(contactUsForm.controls.phone.hasError('minlength'))
                && !(contactUsForm.controls.phone.hasError('pattern'))">
                    Phone is required</mat-error>
                <mat-error *ngIf="contactUsForm.controls.phone.hasError('minlength') && !(contactUsForm.controls.phone.hasError('pattern'))">
                    Phone is less than 10 digits
                </mat-error>
                <mat-error *ngIf="contactUsForm.controls.phone.hasError('pattern')">Phone is invalid</mat-error>
            </mat-form-field>
        </section>
        <section class="display-flex">
            <mat-form-field class="field-style">
                <input id="email" matInput placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="contactUsForm.controls.email.hasError('email')">Email is invalid</mat-error>
                <mat-error *ngIf="contactUsForm.controls.email.touched && !email && !(contactUsForm.controls.email.hasError('email'))">
                    Email is required</mat-error>
            </mat-form-field>
            <mat-form-field class="field-style align">
                <textarea id="message" matInput placeholder="Message" formControlName="message" required
                    maxlength="450"></textarea>
                <mat-error *ngIf="contactUsForm.controls.message.touched && !message"> Message is required</mat-error>
            </mat-form-field>
        </section>
    </form>
    <div>
        <re-captcha size="invisible" #invisible (resolved)="sendMessage($event)"></re-captcha>
        <button type="submit" (click)="invisible.execute()" class="button-style" id="submitButton" name="contact-submit"
            [class.disabled]="!!isLoading  || contactUsForm.invalid" [disabled]="!!isLoading || contactUsForm.invalid">
            <img *ngIf="!!isLoading" src="{{fullImgPath}}loading-gif-icon.gif" class="gif-proccess">
            Submit </button>

    </div>
    <div>
        <p class="p-style">Operating hours: 10:00 AM - 6:00 PM
            Monday - Friday</p>
    </div>
    <div class="flex-img">
        <a id="facebook-icon" href="{{fb}}" rel="noopener" target="_blank"><img src="{{fullImgPath}}fb-contact.png"
                class="fb-icon-style" alt="facebook"></a>
        <a id="linkedin-icon" href="{{linkedIn}}" rel="noopener" target="_blank"><img src="{{fullImgPath}}linked-in-contact.png"
                class="link-in-icon-style" alt="linkedin"></a>
    </div>
</div>