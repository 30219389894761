import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentfulService } from 'src/app/core/services/contentful.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  public jobs: Observable<any>;
  constructor(private contentful: ContentfulService) { }

  ngOnInit() {
    this.jobs = this.contentful.getContent('careers');
  }

}
