<div class="common">
    <div class="inline-flex">
        <div class="h1-header">
            <h1 class="height-42">Find a job you love, <span>create the future you want</span></h1>
        </div>
        <div class="img-style" *ngIf="innerWidth > 1024">
            <img src="{{fullImgPath}}careersIcon-desktop.png" alt="Careers">
        </div>
        <div class="img-style" *ngIf="innerWidth < 1025 && innerWidth > 739">
            <img src="{{fullImgPath}}careersIcon-tablet.png" alt="Careers">
        </div>
        <div class="img-style" *ngIf="innerWidth < 740">
            <img src="{{fullImgPath}}careersIcon-mobile.png" alt="Careers">
        </div>
    </div>
    <div class="description">
        <p>Looking to join an amazing team with lots of perks? Come talk to us at PavoCom.<br><br>
            It’s really simple, we are developers, quality assurance engineers, developers & customer support
            rock
            stars and it’s always good to know like-minded folks.
        </p>
    </div>
    <div>
        <div class="title">
            <h5>Why PavoCom?</h5>
        </div>
        <div class="pavo-description">
            <p>Want to make a difference? So do we!<br><br>
                Do you like working in an environment where you can implement new ideas?<br>
                PavoCom is a place for big thinkers who enjoying taking on fresh challenges as a team. Based in Amman,
                Jordan and serving clients throughout the region and North America, we’re a group of passionate
                front-end and back end developers working on the cutting edge of mobile and telecom
                technologies.<br><br>
                We’re almost always looking for people who can bring new perspectives and experience to our team. If
                you are slightly obsessive about customer/user experiences and you’re looking for a place that values
                your curiosity, passion, desire to learn – then please check out our open roles.
            </p>
        </div>
        <div class="title">
            <h5>Open Vacancies</h5>
        </div>
        <section>
            <app-jobs></app-jobs>
        </section>
        <div class="title center">
            <h5>Let’s Talk</h5>
        </div>
        <div class="letsTalk-description">
            <p>Open roles change all the time and sometimes we’re moving so fast we haven’t gotten the new job online
                yet. SO if you’ve got the right technical chops in UI/UX or are an Angular front end engineer or a Java
                software developer, etc. but are just casually looking for your exact right next move, and curious
                about what we’re about, that’s ok too. Please just make a note of this and email your resume to </p>
        </div>
    </div>
</div>