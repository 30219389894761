<!-- <div fxLayout="row" fxLayoutAlign="space-around center"  fxFlex="30%"  *ngFor="let item of employees | async" > -->

<div class="profile-img-display">
    <div class="profile-img-section" *ngFor="let item of employees | async">
        <div class="img height-without-arrow">
            <img src="https://{{item.fields.employeeImage.fields.file.url}}" alt="employee">
        </div>
        <a class="arrow">
            <!-- <img src="{{fullImgPath}}arrow.png" alt="arrow"> -->
        </a>
        <div class="position-relative">
            <div class="name">
                <h6>{{item.fields.employeeName}}</h6>
            </div>
            <div class="position">
                <p>{{item.fields.employeePosition}}</p>
            </div>
        </div>
    </div>
</div>