import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public fullImgPath = environment.fullImgPath;
  public innerWidth: any;
  public showReadMore = false;
  public showOrHideRead = true;

  constructor() {
   }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }
  public readMore() {
    this.showReadMore = true;
    this.showOrHideRead = !this.showOrHideRead;
  }
  public readLess() {
    this.showReadMore = false;
    this.showOrHideRead = !this.showOrHideRead;
  }
  @HostListener('window:resize', ['$event'])
 onResize(event) {
 this.innerWidth = window.innerWidth;
 }

}
