import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/component/home/home.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { ProductsComponent } from './component/products/products.component';
import { ServicesComponent } from './component/services/services.component';
import { CareersComponent } from './component/careers/careers.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { JobPostComponent } from './component/job-post/job-post.component';
import { JobResponsibilitiesComponent } from './component/job-responsibilities/job-responsibilities.component';

export const ROUTE_URLS = {
  HOME: 'home',
  CONTACT_US: 'contact-us',
  PRODUCT: 'products',
  SERVICES: 'services',
  CAREERS: 'careers',
  ABOUT_US: 'about-us',
  JOP_POST: 'careers/:jobPosition/:jobId/:contentTitle/application-form',
  JOP_RESPONSIBILITIES: 'careers/:jobPosition/:jobId/:contentTitle'
};

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: ROUTE_URLS.HOME, component: HomeComponent },
  { path: ROUTE_URLS.SERVICES, component: ServicesComponent },
  { path: ROUTE_URLS.PRODUCT, component: ProductsComponent },
  { path: ROUTE_URLS.CONTACT_US, component: ContactUsComponent },
  { path: ROUTE_URLS.CAREERS, component: CareersComponent },
  { path: ROUTE_URLS.ABOUT_US, component: AboutUsComponent },
  { path: ROUTE_URLS.JOP_POST, component: JobPostComponent },
  { path: ROUTE_URLS.JOP_RESPONSIBILITIES, component: JobResponsibilitiesComponent},

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, ContactUsComponent, ProductsComponent,
  ServicesComponent, CareersComponent, JobResponsibilitiesComponent, JobPostComponent];


