<div class="container-flex">
  <div class="visible" *ngIf="innerWidth >= 740">
    <a id="header-pavo-logo" routerLink="/">
      <img src="{{fullImgPath}}logo.png" class="logo" alt="pavo site logo">
    </a>

    <a id="header-li" href="{{linkedIn}}" rel="noopener" target="_blank"><img src="{{fullImgPath}}linkedin-letter.png"
        class="icon right-align" alt="linked in logo"></a>
    <a id="header-fb" href="{{fb}}" rel="noopener" target="_blank"> <img src="{{fullImgPath}}facebook-letter-logo.png"
        class="icon" alt="facebook logo"></a>
    <div class="container-flex-row content-style">
      <ul>
        <li> <a id="contact-us" routerLink="/contact-us">Contact Us</a></li>
        <li> <a id="careers" routerLink="/careers">Careers</a></li>
        <li> <a id="services" routerLink="/services">Services</a></li>
        <li> <a id="products" routerLink="/products">Products</a> </li>
        <li> <a id="about-pavocom" routerLink="/about-us">About Pavocom</a> </li>
      </ul>

    </div>
  </div>

  <div *ngIf="innerWidth < 740">
    <div *ngIf="!isMenuShowing">
      <div>
        <a id="header-pavo-logo" routerLink="/">
          <img src="{{fullImgPath}}logo-red.png" class="logo-red" alt="pavo site logo">
        </a>
      </div>
      <div class="col-md-4 col-0">
        <a id="menu" (click)="display()"><img src="{{fullImgPath}}three-dash.png" class="three-dots" alt="three-dashes">
        </a> </div>
    </div>
  </div>
  <div [class.shown-menu]="!!isMenuShowing && !!headerContent" *ngIf="!!isMenuShowing && !!headerContent">
    <div class="content">
      <div>
        <a id="header-pavo-logo" (click)="display()">
          <img src="{{fullImgPath}}pavo-logo-white.png" class="white-logo" alt="pavo site logo">
        </a>
      </div>
      <a id="close" (click)="display()">
        <i class="Exit-icon"><img src="{{fullImgPath}}exit-icon.svg" alt="close-icon"></i>
      </a>
      <div class="content-style container-grid active">
        <a id="about-pavocom" routerLink="/about-us" (click)="hideMenu()">About Pavocom</a>
        <a id="products" routerLink="/products" (click)="hideMenu()">Products</a>
        <a id="services" routerLink="/services" (click)="hideMenu()">Services</a>
        <a id="careers" routerLink="/careers" (click)="hideMenu()">Careers</a>
        <a id="contact-us" routerLink="/contact-us" (click)="hideMenu()">Contact Us</a>
      </div>
      <a id="header-fb" href="{{fb}}" rel="noopener" target="_blank"> <img src="{{fullImgPath}}fb-logo-w.png"
          class="icon-style" alt="facebook logo"></a>
      <a id="header-li" href="{{linkedIn}}" rel="noopener" target="_blank"><img src="{{fullImgPath}}linkedin-w.png"
          class="icon-style" alt="linked in logo"></a>
      <div class="wave">
      </div>
    </div>
  </div>
</div>