<div class="common" *ngIf="innerWidth > 739">
    <div class="h1-header">
        <h1> A passion for all things mobile </h1>
    </div>
    <div class="inline-flex">
        <div class="h2-subHeader">
            <h2> Think of us as an accelerator for mobile services </h2>
        </div>
        <div *ngIf="innerWidth >1024">
                <img class="img-style" src="{{fullImgPath}}home-pic1.png" alt="home-pic1">
            </div>
            <div *ngIf="innerWidth <1025 && innerWidth >739">
                    <img class="img-style top" src="{{fullImgPath}}tablet-pic1.png" alt="home-pic1">
           </div>
    </div>
    <div class="p-style">
        <p>All the right experts, under one roof, building the tools and working to create flexible
            software applications for many telecom clients. From Cloud solutions to IoT through to
            consumer wireless, we get our energy from innovating on behalf of businesses and people
            worldwide.</p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="about-pavocom" routerLink="/about-us"> About Pavocom </a></h2>
    </div>
    <div class="h2-subHeader">
        <h2> The Team </h2>
    </div>
    <div class="p-style">
        <p>Work out enthusiasts to music aficionados, artists to parents.
            We’re a diverse but close-knit team united by a passion for all things mobile …
            and cake.</p>
    </div>

    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="take-alook" routerLink="/about-us" fragment="employees"> Take a look </a></h2>
    </div>
    <div class="inline-flex">
        <div class="h2-subHeader">
            <h2> Products </h2>
        </div>
        <div *ngIf="innerWidth >1024">
                <img class="img-style special-margins" src="{{fullImgPath}}home-pic2.png" alt="home-pic2">
            </div>
            <div *ngIf="innerWidth <1025 && innerWidth >739">
                    <img class="img-style special-margins" src="{{fullImgPath}}tablet-pic2.png" alt="home-pic2">
           </div>
     
    </div>
    <div class="p-style">
        <p>As a full-service agile software development shop, we help companies with everything from designing
            cloud-based applications through to projects to harness the power of the Internet of Things.</p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="learn-more" routerLink="/products"> Learn more </a></h2>
    </div>
    <div class="h2-subHeader">
        <h2> Services </h2>
    </div>
    <div class="p-style">
        <p>As a full-service agile software development shop, we help companies with everything from designing
            cloud-based
            applications through to projects to harness the power of the Internet of Things.</p>
    </div>
    <div class="inline-flex">
    <div class="link-margins basis">
        <h2 class="h2-subHeader"><a id="learn-more" routerLink="/services"> Learn more </a></h2>
    </div>
    <div *ngIf="innerWidth >1024">
        <img class="img-style special-margins2" src="{{fullImgPath}}home-pic3.png" alt="home-pic3">
    </div>
    <div *ngIf="innerWidth <1025 && innerWidth >739">
            <img class="img-style special-margins2" src="{{fullImgPath}}tablet-pic3.png" alt="home-pic3">
   </div>
    </div>
    <div class="h2-subHeader">
        <h2> Careers </h2>
    </div>
    <div class="p-style">
        <p>Do you think you’re a good fit for our company? We’re always on the look out
            for talented and motivated tech experts to join our team. </p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="view-jobs" routerLink="/careers"> View jobs </a></h2>
    </div>
</div>

<div class="common" *ngIf="innerWidth < 740">
    <div class="h1-header">
        <h1> A passion for all things mobile </h1>
    </div>
 <div class="flex"> 
    <div>
        <img class="img-style" src="{{fullImgPath}}mobile-pic1.png" alt="mobile-pic1">
    </div>
    <div class="h2-subHeader">
        <h2> Think of us as an accelerator for mobile services </h2>
    </div>
    <div class="p-style">
        <p>All the right experts, under one roof, building the tools and working to create flexible
            software applications for many telecom clients. From Cloud solutions to IoT through to
            consumer wireless, we get our energy from innovating on behalf of businesses and people
            worldwide.</p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="about-pavocom" routerLink="/about-us"> About Pavocom </a></h2>
    </div>
    <div>
        <img class="img-style2" src="{{fullImgPath}}mobile-pic2.png" alt="mobile-pic2">
    </div>
    <div class="h2-subHeader">
        <h2> The Team </h2>
    </div>
    <div class="p-style">
        <p>Work out enthusiasts to music aficionados, artists to parents.
            We’re a diverse but close-knit team united by a passion for all things mobile …
            and cake.</p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="take-Alook" routerLink="/about-us" fragment="employees"> Take a look </a></h2>
    </div>
    <div>
        <img  class="img-style3" src="{{fullImgPath}}mobile-pic3.png" alt="mobile-pic3">
    </div>
    <div class="h2-subHeader">
        <h2> Products </h2>
    </div>
    <div class="p-style">
        <p>As a full-service agile software development shop, we help companies with everything from designing
            cloud-based applications through to projects to harness the power of the Internet of Things.</p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="learn-more" routerLink="/products"> Learn more </a></h2>
    </div>
    <div class="h2-subHeader">
        <h2> Services </h2>
    </div>
    <div class="p-style">
        <p>As a full-service agile software development shop, we help companies with everything from designing
            cloud-based
            applications through to projects to harness the power of the Internet of Things.</p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="learn-more" routerLink="/services"> Learn more </a></h2>
    </div>
    <div class="h2-subHeader">
        <h2> Careers </h2>
    </div>
    <div class="p-style">
        <p>Do you think you’re a good fit for our company? We’re always on the look out
            for talented and motivated tech experts to join our team. </p>
    </div>
    <div class="link-margins">
        <h2 class="h2-subHeader"><a id="view-jobs" routerLink="/careers"> View jobs </a></h2>
    </div>
</div>