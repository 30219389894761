import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Job } from '../../core/models/job.model';



@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private client = contentful.createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.token

  });
  constructor() { }
  public getField(contentId): Observable<any> {
    const promise = this.client.getEntry(contentId);
    return from(promise)
      .pipe(
        map(entry => entry.fields)
      );
  }
  public getContent(contentId): Observable<any> {
    const p = this.client.getEntries({ content_type: contentId });
    return from(p)
      .pipe(
        map(entry => entry.items)
      );
  }
  public getJobDesc(contentId, jobId) {
    const p = this.client.getEntries({
      'fields.jobId': jobId,
      'content_type': contentId
    });
    return from(p)
      .pipe(
        map(entry => entry.items[0].fields)
      );
  }
}

