<div class="common">
    <div class="inline-flex">
        <div class="h1-header">
            <h1>A privately held company built on innovation and integrity</h1>
        </div>
        <div class="img-style" *ngIf="innerWidth > 1024">
            <img src="{{fullImgPath}}services-desktop.png" alt="Services">
        </div>
        <div class="img-style" *ngIf="innerWidth < 1025">
            <img src="{{fullImgPath}}services-taplet.png" alt="Services">
        </div>
    </div>
    <div class="top">
        <div class="margin-top">
            <h2 class="h2-header">Fleet Management</h2>
            <p class="description">As an ATT partner, we offer affordable GPS tracking and analytics to keep your fleet
                moving, increasing the return of your assets and improving safe driving behavior requires real-time,
                actionable insights.</p>
        </div>
        <div>
            <h2 class="features">Features:</h2>
        </div>
        <div class="inline-flex">
            <div class="flex-33">
                <h2 class="feature-header-1">1. Comprehensive on-borad vehicle diagnostics</h2>
                <p class="feature-description-1">With a single device, collect critical data on vehicle performance,
                    driver
                    behaviors and encourage safer
                    operation.</p>
            </div>
            <div class="flex-33 left-28">
                <h2 class="feature-header-2">2. Plug and Play</h2>
                <p class="feature-description-2">Easy to install GPS device, without any installation costs</p>
            </div>
            <div class="flex-33">
                <h2 class="feature-header-3">3. Flexible and scalable platform</h2>
                <p class="feature-description-3">Telematics field data is delivered to a single view for fleet
                    managers,
                    also allowing response to wide
                    variety of departmental/organization needs</p>
            </div>
        </div>
        <div class="margin-top">
            <h2 class="h2-header telecom-mob">Telecom Accelerator</h2>
            <p class="description">An end to end E-commerce solutions that can take any private wireless label into
                production in 29 days. The Telecom Accelerator is a collection of modular modern stack E-commerce
                services
                built to deliver the best user experience for your private wireless label.
            </p>
        </div>
        <div>
            <h2 class="CMM-features">Features:</h2>
        </div>
        <div class="inline-flex inline-block-100 inline-flex-mob margin">
            <div class="flex-basis inline-block">
                <img class="cloud-icon" src="{{fullImgPath}}Features-CloudNative.png" alt="Features Cloud Native">
                <h2 class="icons-feature">1. Cloud Native</h2>
                <p class="cloud-description">Built with Kubernetes and docker to run on cloud services using
                    continuous
                    integration and deployment to deliver features and support on demand.</p>
            </div>
            <div class="flex-basis  inline-block">
                <img class="modular-icon" src="{{fullImgPath}}Modular-Design.png" alt="Modular Design">
                <h2 class="icons-feature">2. Modular Design</h2>
                <p class="modular-description">The heart of all the services lies in modular design and the
                    adoption of
                    microservice architecture in order to support the most aggressive scaling needs.</p>
            </div>

            <div class="flex-basis tablet-align margin-top-44">
                <img class="modern-icon" src="{{fullImgPath}}Modern-web.png" alt="Modern web">
                <h2 class="icons-feature">3. Modern Web</h2>
                <p class="modern-description">Our single page web application is built to support the speed
                    requirements
                    while leveraging headless CMS to simplify the needs of your marketing team.</p>
            </div>
        </div>
        <div>
            <h2 class="features align-center">Our Customers</h2>
        </div>
        <div class="inline-flex inline-flex-mob flex-tablet top-36 bottom-76">
            <div class="flex-25">
                <img class="g2g-icon" src="{{fullImgPath}}G2G-logo.png" alt="G2G Logo">
            </div>
            <div class="flex-25">
                <img class="freeup-icon" src="{{fullImgPath}}Freeup.png" alt="Freeup Logo">
            </div>
            <div class="flex-25">
                <img class="freds-icon" src="{{fullImgPath}}Freds.png" alt="Fred's Logo">
            </div>
            <div class="flex-25">
                <img class="hero-icon" src="{{fullImgPath}}Hero.png" alt="Hero Logo">
            </div>
        </div>
    </div>
</div>