import { Component, OnInit, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JobPostModel } from '../../core/models/job-post.model';
import { ContentfulService } from 'src/app/core/services/contentful.service';
import { JobPostService } from '../../core/services/job-post.service';
import { MatSnackBar, MatSnackBarConfig, } from '@angular/material';
import { environment } from '../../../environments/environment';
import { FileUpload } from '../../core/models/fileUpload';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from 'angularfire2/storage';

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobPostComponent implements OnInit {
  @Input() contentTitle: string;
  @Input() jobId: string;
  @Input() jobPosition: string;

  public showReadMore = false;
  public showOrHideRead = true;
  public isLoading = false;
  public type: string;
  public bucketName: string;
  public phone: string;
  public title: string;
  public idJob: string;
  public position: string;
  public value: string;
  public viewValue: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public location: string;
  public languages: string;
  public workEligibility: string;
  public gender: string;
  public leadSource;
  public visaSponsorship;
  public socialProfile;
  public resumeFile;
  public coverLetterFile;
  public hispanic;
  public fullImgPath = environment.fullImgPath;
  public linkedInJobs = environment.LinkedInJobsLink;
  public jobPostForm: FormGroup;
  public config = new MatSnackBarConfig();
  public user: JobPostModel = new JobPostModel();
  public selectedFiles: FileList;
  public coverUpload: FileUpload;
  public resumeUpload: FileUpload;
  public ref: AngularFireStorageReference;
  public task: AngularFireUploadTask;
  public uploadProgress: any;
  public uploadState: any;
  public innerWidth: any;

  constructor(private formBuilder: FormBuilder, private contentful: ContentfulService,
              private route: ActivatedRoute, private jobPostService: JobPostService, public snackBar: MatSnackBar,
              private afStorage: AngularFireStorage) {
    this.jobPostForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]+[ \t]?[A-Za-z]+[ \t]?[A-Za-z ]*$')])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]+[ \t]?[A-Za-z]+[ \t]?[A-Za-z ]*$')])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'),
      Validators.minLength(10), Validators.maxLength(10)])],
      location: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]+[ \t]?[A-Za-z]+[ \t]?[A-Za-z ]*$')])],
      languages: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]+[ \t]?[A-Za-z]+[ \t]?[A-Za-z ]*$')])],
      workEligibility: ['', Validators.required],
      gender: ['', Validators.required],
      leadSource: [''],
      visaSponsorship: ['', Validators.required],
      hispanic: [''],
      socialProfile: [''],
      resumeFile: ['', Validators.required],
      coverLetterFile: [''],
    });
    this.config.horizontalPosition = 'center';
    this.config.verticalPosition = 'top';
    this.config.panelClass = ['bar-class'];
  }
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.title = this.route.snapshot.paramMap.get('contentTitle').charAt(0).toUpperCase()
      + this.route.snapshot.paramMap.get('contentTitle').slice(1).toLowerCase().split('-').join(' ');
    this.idJob = this.route.snapshot.paramMap.get('jobId').toUpperCase();
    this.position = this.route.snapshot.paramMap.get('jobPosition').charAt(0).toUpperCase() +
      this.route.snapshot.paramMap.get('jobPosition').slice(1).toLowerCase();

  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.setAttchament(event);
    event.target.value = null;
  }

  triggerButton(event, type) {
    this.type = type;
    event.preventDefault();
    const element: HTMLElement = document.getElementById(`upload${type}`);
    element.click();
  }

  private setAttchament(event) {
    if (this.type === 'resume') { this.resumeUpload = event.target.files[0]; } else { this.coverUpload = event.target.files[0]; }
  }
  public removeAttachment(type) {
    if (type === 'resume') {
      this.resumeUpload = null;
      this.jobPostForm.controls.resumeFile.setErrors({ incorrect: true });
      this.resumeUpload = null;
    } else { this.coverUpload = null; }
  }

  upload() {
    this.bucketName = 'Resume';
    this.ref = this.afStorage.ref(`uploads/${this.bucketName}/${this.title}-${this.resumeUpload.name}`);
    this.task = this.ref.put(this.resumeUpload);
    this.resumeUpload = null;
    if (typeof this.coverUpload !== 'undefined' && this.coverUpload) {
      // this.coverUpload && this.coverUpload != null && this.coverUpload != undefined)
      this.bucketName = 'CoverLetter';
      this.ref = this.afStorage.ref(`uploads/${this.bucketName}/$${this.title}-${this.coverUpload.name}`);
      this.task = this.ref.put(this.coverUpload);
      this.coverUpload = null;
    }
  }
  public readMore() {
    this.showReadMore = true;
    this.showOrHideRead = !this.showOrHideRead;
  }
  public readLess() {
    this.showReadMore = false;
    this.showOrHideRead = !this.showOrHideRead;
  }
  public submitApplication(recaptchaRes) {
    if (!!recaptchaRes) {
      this.isLoading = true;
      this.user = ({
        location: this.jobPostForm.get('location').value,
        email: this.jobPostForm.get('email').value,
        phone: this.jobPostForm.get('phone').value,
        firstName: this.jobPostForm.get('firstName').value,
        lastName: this.jobPostForm.get('lastName').value,
        languages: this.jobPostForm.get('languages').value,
        workEligibility: this.jobPostForm.get('workEligibility').value,
        leadSource: this.jobPostForm.get('leadSource').value,
        visaSponsorship: this.jobPostForm.get('visaSponsorship').value,
        gender: this.jobPostForm.get('gender').value,
        hispanic: this.jobPostForm.get('hispanic').value,
        socialProfile: { platform: 'LinkedIn', url: this.jobPostForm.get('socialProfile').value },
        position: { id: this.idJob, city: this.position, title: this.title },
      });
      this.config.duration = 20000;
      this.jobPostService.applyToJob(this.user)
        .then((res) => {
          this.upload();
          this.isLoading = false;
          this.snackBar.open('Your request is sent successfuly!', '', this.config);
          this.jobPostForm.reset();
        },
          (error) => {
            this.snackBar.open('something went worng!', '', this.config);
          });
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}

