<div class="common">
    <div class="job-inline-flex">
        <div class="h1-header-flex">
            <h1 class="job-h1-header">
                {{title| titlecase}}
            </h1>
        </div>
    </div>
    <form class="job-post-form" [formGroup]="jobPostForm" (ngSubmit)="invisible.execute()">
        <section>
            <mat-form-field class="field-style" disableRipple>
                <input matInput placeholder="First Name" formControlName="firstName" required>
                <mat-error
                    *ngIf="jobPostForm.controls.firstName.touched && !firstName && !(jobPostForm.controls.firstName.hasError('pattern'))">
                    First Name is required</mat-error>
                <mat-error *ngIf="jobPostForm.controls.firstName.hasError('pattern')">First Name is invalid</mat-error>
            </mat-form-field>
            <mat-form-field class="field-style" disableRipple>
                <input matInput placeholder="Last Name" formControlName="lastName" required>
                <mat-error
                    *ngIf="jobPostForm.controls.lastName.touched && !lastName && !(jobPostForm.controls.lastName.hasError('pattern'))">
                    Last Name is required</mat-error>
                <mat-error *ngIf="jobPostForm.controls.lastName.hasError('pattern')">Last Name is invalid</mat-error>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field class="field-style">
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="jobPostForm.controls.email.hasError('email')">Email is invalid</mat-error>
                <mat-error
                    *ngIf="jobPostForm.controls.email.touched && !email && !(jobPostForm.controls.email.hasError('email'))">
                    Email is required</mat-error>
            </mat-form-field>
            <mat-form-field class="field-style">
                <input matInput placeholder="Phone" maxlength="10" minlength="10" formControlName="phone" required>
                <mat-error
                    *ngIf="jobPostForm.controls.phone.touched && !phone && !(jobPostForm.controls.phone.hasError('minlength'))  && !(jobPostForm.controls.phone.hasError('pattern'))">
                    Phone is required</mat-error>
                <mat-error *ngIf="jobPostForm.controls.phone.hasError('minlength') && !(jobPostForm.controls.phone.hasError('pattern'))"> Phone is less than 10 digits
                </mat-error>
                <mat-error *ngIf="jobPostForm.controls.phone.hasError('pattern')"> Phone is not valid
                </mat-error>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field class="field-style">
                <input matInput placeholder="Location (City)" formControlName="location" required>
                <mat-error
                    *ngIf="jobPostForm.controls.location.touched && !location && !(jobPostForm.controls.location.hasError('pattern'))">
                    Location is required</mat-error>
                <mat-error *ngIf="jobPostForm.controls.location.hasError('pattern')">Location is invalid</mat-error>
            </mat-form-field>
        </section>
        <div class="job-inline-flex margin-left-14 non-flex">

            <div class="flex-50">
                <p class="job-p-style"> Resume / CV </p>
                <input id="uploadresume" type="file" (change)="selectFile($event)" formControlName="resumeFile"
                    accept=".doc,.pdf,.docx,.txt,.rtf" required>
                <a class="link-style" (click)="triggerButton($event,'resume')">Attach</a>

                <div  *ngIf="resumeUpload != null">
                    <label>{{resumeUpload.name}}</label>
                    <button mat-icon-button aria-label="remove attachement" (click)="removeAttachment('resume')">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
            <div class="flex-50">
                <p class="job-p-style"> Cover Letter </p>
                <input id="uploadcoverLetter" type="file" (change)="selectFile($event)"  formControlName="coverLetterFile"  accept=".doc,.pdf,.docx,.txt,.rtf"/>
                <a class="link-style" (click)="triggerButton($event,'coverLetter')">Attach</a>
                
                <div  *ngIf="coverUpload != null">
                        <label>{{coverUpload.name}}</label>
                        <button mat-icon-button aria-label="remove attachement" (click)="removeAttachment('coverLetter')">
                            <mat-icon>delete</mat-icon>
                        </button>
                </div>
            </div>
        </div>
        <br> <br>
        <section>
            <mat-form-field class="field-style">
                <input matInput placeholder="LinkedIn Profile" formControlName="socialProfile">
            </mat-form-field>
            <mat-form-field class="field-style">
                <input matInput placeholder="In addition to fluency in English, which other language do you speak?"
                    formControlName="languages" required>
                <mat-error
                    *ngIf="jobPostForm.controls.languages.touched && !languages && !(jobPostForm.controls.languages.hasError('pattern'))">
                    This field is required</mat-error>
                <mat-error *ngIf="jobPostForm.controls.languages.hasError('pattern')">The text you entered is invalid
                </mat-error>
            </mat-form-field>
        </section>
        <section>
     <mat-form-field class="field-style">
         <mat-label>Are you eligible to work in Jordan without restrictions?</mat-label>
             <mat-select formControlName="workEligibility" required>
              <mat-option value="Yes">Yes</mat-option>
                 <mat-option value="No">No</mat-option>
                  </mat-select>
                  <mat-error
                  *ngIf="jobPostForm.controls.workEligibility.touched && !workEligibility">
                  This field is required</mat-error>
                    </mat-form-field>
            <mat-form-field class="field-style">
                <input matInput placeholder="How did you hear about this job? " formControlName="leadSource">
            </mat-form-field>

        </section>
        <section class="special-section-left">
            <div class="radio-button-desc">
                Will you ever in the future need sponsorship to work in the country
                where this position is located? (If you are uncertain about your response
                to this question, please follow up with a member of the Recruiting team
                if you are contacted about possible next steps.)<span class="red-star">*</span>
            </div>
            <div class="radio-button-style">
                <label> <input type="radio" value="Yes" formControlName="visaSponsorship" [checked]="true"> Yes </label>
                <br>
                <label class="left"> <input type="radio" value="No" formControlName="visaSponsorship"> No
                </label>
            </div>
        </section>
        <section class="special-section-left">
            <h2 class="section-h2-header">U.S. Equal Opportunity Employment Information (Completion is voluntary) </h2>
            <p class="section-p">
                Individuals seeking employment at IDEO are considered without
                regards to race, color, religion, national origin, age, sex, marital status,
                ancestry, physical or mental disability, veteran status, gender identity,
                or sexual orientation. You are being given the opportunity to provide the
                following information in order to help us comply with federal and state
                Equal Employment Opportunity/Affirmative Action record keeping, reporting,
                and other legal requirements.
            </p>
            <p class="section-p"> Completion of the form is entirely voluntary. Whatever your decision,
                it will not be considered in the hiring process or thereafter.
                Any information that you do provide will be recorded and maintained in a
                confidential file.
            </p>
        </section>
        <section class="special-section-left mat-block height-90">
            <mat-form-field>
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender" required>
                    <mat-option value="male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                </mat-select>
                <mat-error
                *ngIf="jobPostForm.controls.gender.touched && !gender">
                This field is required</mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="field-margin-left">
                <mat-label>Ethnicity</mat-label>
                <mat-select formControlName="hispanic">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field> -->
        </section>
    </form>
    <div>
        <a class="a-style">Race & Ethnicity Definitions</a>
    </div>
    <div>

        <p class="section-p-desc">If you believe you belong to any of the categories of protected veterans listed
            below,
            please indicate by
            making the appropriate selection. As a government contractor subject to Vietnam Era Veterans Readjustment
            Assistance Act (VEVRAA), we request this information in order to measure the effectiveness of the outreach
            and positive recruitment efforts we undertake pursuant to VEVRAA. Classification of protected categories is
            as follows:</p>
        <div *ngIf="innerWidth > 739">
            <p class="section-p-desc"> A “disabled veteran” is one of the following: a veteran of the U.S. military,
                ground,
                naval or air service
                who is entitled to compensation (or who but for the receipt of military retired pay would be entitled
                to
                compensation) under laws administered by the Secretary of Veterans Affairs; or a person who was
                discharged
                or released from active duty because of a service-connected disability.</p>

            <p class="section-p-desc"> A “recently separated veteran” means any veteran during the three-year period
                beginning on the date of such
                veteran’s discharge or release from active duty in the U.S. military, ground, naval, or air service.</p>

            <p class="section-p-desc"> An “active duty wartime or campaign badge veteran” means a veteran who served on
                active duty in the U.S.
                military, ground, naval or air service during a war, or in a campaign or expedition for which a
                campaign
                badge has been authorized under the laws administered by the Department of Defense.</p>

            <p class="section-p-desc"> An “Armed forces service medal veteran” means a veteran who, while serving on
                active
                duty in the U.S.
                military, ground, naval or air service, participated in a United States military operation for which an
                Armed Forces service medal was awarded pursuant to Executive Order 12985.</p>
        </div>
        <div *ngIf="innerWidth < 740">
            <div class="read" *ngIf="!!showOrHideRead">
                <span (click)="readMore()">Read more</span>
            </div>
            <div *ngIf="showReadMore">
                <p class="section-p-desc"> A “disabled veteran” is one of the following: a veteran of the U.S.
                    military,
                    ground,
                    naval or air service
                    who is entitled to compensation (or who but for the receipt of military retired pay would be
                    entitled to
                    compensation) under laws administered by the Secretary of Veterans Affairs; or a person who was
                    discharged
                    or released from active duty because of a service-connected disability.</p>

                <p class="section-p-desc"> A “recently separated veteran” means any veteran during the three-year
                    period
                    beginning on the date of such
                    veteran’s discharge or release from active duty in the U.S. military, ground, naval, or air
                    service.
                </p>

                <p class="section-p-desc"> An “active duty wartime or campaign badge veteran” means a veteran who
                    served
                    on
                    active duty in the U.S.
                    military, ground, naval or air service during a war, or in a campaign or expedition for which a
                    campaign
                    badge has been authorized under the laws administered by the Department of Defense.</p>

                <p class="section-p-desc"> An “Armed forces service medal veteran” means a veteran who, while serving
                    on
                    active
                    duty in the U.S.
                    military, ground, naval or air service, participated in a United States military operation for
                    which
                    an
                    Armed Forces service medal was awarded pursuant to Executive Order 12985.</p>
            </div>
            <div class="read" *ngIf="!showOrHideRead">
                <span (click)="readLess()">Read less</span>
            </div>
        </div>
    </div>
    <re-captcha size="invisible" #invisible (resolved)="submitApplication($event)"></re-captcha>
    <div>
        <button class="submit-app-button" (click)="invisible.execute()" [disabled]="!!isLoading || jobPostForm.invalid"
            [class.disabled]="!!isLoading || jobPostForm.invalid">
            <img *ngIf="!!isLoading" src="{{fullImgPath}}loading-gif-icon.gif" class="gif-proccess-apply">
            Submit Application</button>
    </div>
</div>