<div class="common">
    <div class="h1-header">
        <h1>About Pavocom</h1>
    </div>
    <div class="description">
        <p>PavoCom is a technical development team passionate about mobility and developing enterprise wireless
            solutions. From the cloud to the next generation of Internet of Things, we’re laser focused on creating
            opportunity for our customers, employees, and ecosystem partners.</p>
        <p>We are company of go-getters and builders. It’s our job to make bold bets. We live to help companies large
            and small harness the power of IP and wireless communications to transform their businesses.</p>
        <p>Working closely with network carriers, our team can provide you with insight into understanding technology
            trends and identify opportunities that can benefit from mobility technologies and an overall mobility
            strategy that covers BYOD, B2C, B2B and M2M solutions.</p>
    </div>
    <div class="title">
        <h5>Our talented teams craft the best code and design amazing user experiences for our clients.</h5>
    </div>
    <div class="team-description">
        <p>Meet Team Awesome. We provide enterprise mobility solutions plus consumer wireless offerings to companies in
            the United States, Canada, Singapore and Malaysia. We’re comfortable being the geniuses behind the scenes,
            but sometimes people want to know who we are.
        </p>
    </div>
    <section id="employees">
        <app-employees></app-employees>
    </section>
    <div class="title">
        <h5>The Differentiator</h5>
    </div>
    <div class="description">
        <p>What sets PavoCom apart is our team. Dedicated and highly experienced mobility experts, we’re in business to
            serve. We believe in being a true partner, not a vendor.</p>
        <p>We work closely with our clients to design the most agile and efficient solution possible, and we’ll provide
            ongoing support and consulting experience to ensure you achieve your goals.<br>
            To us, every enterprise mobility project is like a fingerprint – no two are alike. Even companies in the
            same industry can have unique goals and challenges that require innovative solutions. For this reason, we
            eschew “one-size-fits-all” recommendations. Instead, we take the time to learn all about our customer’s
            organization - your size, objectives, history, risks – then create a customer offering that delivers
            exactly what you need.</p>
    </div>
</div>