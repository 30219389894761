import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ContactUsService } from '../../core/services/contact-us.service';
import { Inquiry } from 'src/app/core/models/inquiry';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public isLoading = false;
  public fullImgPath = environment.fullImgPath;
  public linkedIn = environment.LinkedInLink;
  public fb = environment.FbLink;
  public name: string;
  public email: string;
  public message: string;
  public phone: string;
  public contactUsForm: FormGroup;
  public userInfo: Inquiry = new Inquiry();
  public config = new MatSnackBarConfig();

  constructor(private formBuilder: FormBuilder, private contactUsService: ContactUsService, public snackBar: MatSnackBar) {
    this.contactUsForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      name: ['', Validators.compose([Validators.required,
      Validators.pattern('^[A-Za-z]+[ \t]?[A-Za-z]+[ \t]?[A-Za-z ]*$')])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10),
      Validators.maxLength(10)])],
      message: ['', Validators.required],
    });
  }

  ngOnInit() {
  }
  public sendMessage(recaptchaRes) {
    if (recaptchaRes) {
      this.isLoading = true;
      this.userInfo = ({
        email: this.contactUsForm.get('email').value,
        name: this.contactUsForm.get('name').value,
        message: this.contactUsForm.get('message').value,
        phone: this.contactUsForm.get('phone').value
      });
      this.config.horizontalPosition = 'center';
      this.config.verticalPosition = 'top';
      this.config.duration = 4000;
      this.config.panelClass = ['bar-class'];
      this.contactUsService.createInquiry(this.userInfo)
        .then((res) => {
          this.isLoading = false;
          this.snackBar.open('Your feedback is sent successfuly!', '', this.config);
          this.contactUsForm.reset();
        },
          (error) => {
            this.snackBar.open('something went worng!', '', this.config);
          });
    }
  }
}
