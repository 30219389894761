import { Component, OnInit, Renderer2, HostListener, Input  } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public fullImgPath = environment.fullImgPath;
  public innerWidth: any;
  constructor(private renderer: Renderer2, private router: Router) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}

