import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatMenuModule, MatFormFieldModule,
         MatInputModule,MatProgressSpinnerModule, MatSnackBarModule, MatCardModule, MatSelectModule, MatIconModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    imports: [MatSelectModule, MatButtonModule, MatCheckboxModule, MatMenuModule, MatFormFieldModule,
        MatInputModule, ReactiveFormsModule, FormsModule, MatCardModule, MatSnackBarModule ,MatProgressSpinnerModule,MatIconModule],
    exports: [MatSelectModule, MatButtonModule, MatCheckboxModule, MatMenuModule, MatFormFieldModule,
        MatInputModule, ReactiveFormsModule, FormsModule, MatCardModule, MatSnackBarModule, MatProgressSpinnerModule,MatIconModule]
})
export class SharedModule { }
