<div class="footer-main">
    <div class="footer-content">
        <div id="footer-pavo-logo" class="pavLogoFooter" (click)="clickHome()">
            <img src="{{fullImgPath}}Pavo-footer.png" alt="PavoLogo">
        </div>
        <div class="footer-menu active">
            <span> <a id="footer-aboutUs" routerLink="/about-us">About Pavocom </a> </span>
            <span> <a id="footer-products" class="margins" routerLink="/products">Products </a></span>
            <span> <a id="footer-services" class="margins" routerLink="/services">Services</a></span>
            <span> <a id="footer-careers" class="margins" routerLink="/careers"> Careers </a></span>
            <span> <a id="footer-contactUs" class="margins" routerLink="/contact-us">Contact Us</a></span>
        </div>
        <div class="footer-icons">
            <a id="footer-fb" href="{{fb}}" rel="noopener" target="_blank"> <img src="{{fullImgPath}}fb-contact.png" class="fb-icon-style"
                    alt="facebook"></a>
            <a id="footer-li" href="{{linkedIn}}" rel="noopener" target="_blank"> <img src="{{fullImgPath}}linked-in-contact.png" class="link-in-icon-style"
                    alt="linkedin"></a>
        </div>
        <div>
            <p class="copyRight">&copy; 2019 Pavocom. All rights reserved.</p>
        </div>
    </div>
</div>