import { Injectable } from '@angular/core';
import { JobPostModel } from '../../core/models/job-post.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobPostService {
  url = 'https://pavocom-site-dev.firebaseapp.com/api/v1/application';
  constructor(private http: HttpClient) { }
  public applyToJob(requester: JobPostModel): Promise<void> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache');

    const options = {
      headers: httpHeaders
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.url, requester, options).subscribe(() => resolve(), (error) => reject());
    });

  }
}
