<div class="Vacancies-display">
    <div class="Vacancies-section" *ngFor="let item of jobs | async">
        <div class="bubble-speech">
            <div class="box">
                <div class="box-header">
                    <h4>{{item.fields.positionTitle}}</h4>
                </div>
                <div class="box-position-header">
                    <h5>{{item.fields.position}}</h5>
                </div>
                <div class="box-description">
                    <p>{{item.fields.positionDescription}}</p>
                </div>
                <div class="Vacancies-inline-flex">
                    <div class="box-link">
                        <a id="apply" [routerLink]="[item.fields.position.toLowerCase(),item.fields.careerId.toLowerCase(),(item.fields.positionTitle).split(' ').join('-').toLowerCase(),'application-form']">
                            {{item.fields.apply}}
                        </a>
                    </div>
                    <div class="box-arrow">
                        <a  [routerLink]="[item.fields.position.toLowerCase(),item.fields.careerId.toLowerCase(),(item.fields.positionTitle).split(' ').join('-').toLowerCase()]"> <img src="https://{{item.fields.arrow.fields.file.url}}"> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>